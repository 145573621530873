import 'normalize.css';

import Vue from 'vue';
import ElementUI from 'element-ui';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

import { APP_VERSION } from '@/config';
import * as message from '@/utils/messageHandler.js';
import * as SportLib from '@/utils/SportLib';
import * as lib from '@/utils/lib';
import * as conf from '@/config/index';
import '@/utils/OddDataList';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/sass/global.scss';
import '@/assets/sass/elementChange.scss';

const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const req = require.context('./assets/icons/svg', true, /\.svg$/);
requireAll(req);

Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });

Vue.config.productionTip = false;

Vue.prototype.$MSG = message;
Vue.prototype.$SportLib = SportLib;
Vue.prototype.$lib = lib;
Vue.prototype.$conf = conf;

window.appVue = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

const script = document.createElement('script');
script.setAttribute('async', true);
script.setAttribute(
  'src',
  `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_TOKEN}`
);
script.onload = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', process.env.VUE_APP_GA_TOKEN);
};
document.body.appendChild(script);

console.log(`${process.env.VUE_APP_DEPLOY} - ${process.env.VUE_APP_UI} ${APP_VERSION}`);
console.log(`git - ${process.env.VUE_APP_GIT_HASH}`);
console.log(`ga - ${process.env.VUE_APP_GA_TOKEN}`);
