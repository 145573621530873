import router from '@/router';
import { apiGetGameCat, apiGetLiveEvents2, apiGetLiveInfo } from '@/api/game';
import { getMenuIconByCatID } from '@/utils/SportLib';
import { MOBILE_APP } from '@/config';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    cats: {},
    lives: [],
    activeCats: [],
    activeCat: 0,
    activeLive: null,
    sortType: 'time',
    updateInterval: null,
  },
  getters: {
    activeLives(state) {
      const { lives, activeCat } = state;
      if (activeCat === 0) {
        return lives;
      } else if (activeCat === 1) {
        return lives.filter(({ CatID }) => CatID === 1 || CatID === 31);
      } else if (activeCat === 'olympic') {
        return lives.filter(({ Olympic }) => Olympic);
      } else {
        return lives.filter(({ CatID }) => CatID === activeCat);
      }
    },
    // 檢查是否有正在進行的賽事
    hasRunningEvents(state) {
      return state.lives.length > 0 && state.lives.some((live) => live.IsRun === true);
    },
  },
  mutations: {
    setActiveCat(state, cat) {
      state.activeCat = cat;
    },
    showLive(state, live) {
      state.activeLive = live;
    },
    hideLive(state) {
      state.activeLive = null;
    },
    setSort(state, sort) {
      state.sortType = sort;
    },
    // 新增：設置更新定時器ID
    setUpdateInterval(state, intervalId) {
      state.updateInterval = intervalId;
    },
    // 更新比分 - 優化版本
    updateScores(state, { event }) {
      // 使用 EvtID 查找賽事
      const liveIndex = state.lives.findIndex((live) => live.EvtID === event.EvtID);

      if (liveIndex === -1) return;

      const live = state.lives[liveIndex];

      // 檢查比分是否有變化
      if (live.HomeScore !== event.HomeScore) {
        Vue.set(live, 'HomeScore', event.HomeScore);
      }

      if (live.AwayScore !== event.AwayScore) {
        Vue.set(live, 'AwayScore', event.AwayScore);
      }

      if (live.TimeAct !== event.TimeAct) {
        Vue.set(live, 'TimeAct', event.TimeAct);
      }
    },
  },
  actions: {
    updateCats({ state }) {
      return apiGetGameCat().then((response) => {
        response.forEach((cat) => {
          const { CatID } = cat;
          cat.icon = getMenuIconByCatID(CatID);
        });
        state.cats = response.reduce((map, cat) => {
          cat.GroupCatIDs.forEach((id) => {
            map = {
              ...map,
              ...{
                [id]: cat,
              },
            };
          });
          return map;
        }, {});
        state.cats.olympic = {
          Name: '奧運',
        };
      });
    },
    updateLives({ dispatch, state, commit, getters }) {
      // 先清除可能存在的舊定時器
      if (state.updateInterval) {
        clearInterval(state.updateInterval);
        commit('setUpdateInterval', null);
      }
      return apiGetLiveEvents2().then((response) => {
        const { cats, lives, activeCats } = state;
        const {
          data: { List: leagues },
        } = response;
        lives.length = 0;

        leagues.forEach((league) => {
          const { CatID, LeagueID, LeagueNameStr: LeagueName, Team: events } = league;
          const cat = cats[CatID];
          events.forEach((event) => {
            const live = {
              ...event,
              CatID: cat.CatID,
              CatName: cat.Name,
              LeagueID,
              LeagueName,
            };
            lives.push(live);
          });
        });

        lives.sort((live1, live2) => {
          const { CatID: cat1, ScheduleTimeStr: time1 } = live1;
          const { CatID: cat2, ScheduleTimeStr: time2 } = live2;
          if (cat1 !== cat2) return cat1 - cat2;
          return time1.localeCompare(time2);
        });

        activeCats.length = 0;
        lives.forEach(({ CatID }) => {
          if (!activeCats.includes(CatID)) {
            activeCats.push(CatID);
          }
        });

        if (lives.some(({ Olympic }) => Olympic)) {
          activeCats.push('olympic');
        }

        // 檢查是否有正在進行的賽事
        if (getters.hasRunningEvents) {
          // 設置定時器每秒更新比分
          const intervalId = setInterval(async () => {
            try {
              // 再次檢查是否有正在進行的賽事
              if (getters.hasRunningEvents) {
                await dispatch('updateLiveInfo');
              } else {
                clearInterval(intervalId);
                commit('setUpdateInterval', null);
              }
            } catch (error) {
              console.error('定時更新出錯:', error);
            }
          }, 1000);

          //保存定時器ID;
          commit('setUpdateInterval', intervalId);
        }
      });
    },
    updateLiveInfo({ state, commit }) {
      return apiGetLiveInfo().then((response) => {
        if (!response || !response?.data || !response?.data?.List) {
          commit('setUpdateInterval', null);
          return;
        }
        // 整合比分資料
        response.data.List.forEach((category) => {
          if (!category.Team.length) return;
          // 遍歷每個類別中的賽事
          category.Team.forEach((event) => {
            // 查找對應的賽事
            const matchingLive = state.lives.find(
              (live) => live.EvtID === event.EvtID && live.CatID === category.CatID
            );

            if (matchingLive) {
              // 使用 updateScores mutation 更新比分
              commit('updateScores', { event });
            }
          });
        });
      });
    },
    openHome() {
      if (MOBILE_APP) {
        router.replace('/Games');
      } else {
        const { href: url } = router.resolve({
          path: 'Games',
        });
        if (url) {
          window.open(url, 'games');
        }
      }
    },
    openTable({ commit, dispatch }, live) {
      if (live) {
        const type = live.IsRun ? 2 : 3;
        const cat = live.CatID;
        if (MOBILE_APP) {
          window.OddData.clear();
          commit('setLoading', true);
          commit(
            'Game/setCatIDAndGameTypeAndWagerType',
            {
              selectGameType: type,
              selectCatID: cat,
              selectWagerTypeKey: 1,
            },
            { root: true }
          );
          commit('MoreGame/closeMoreGameList', null, { root: true });
          dispatch('Game/updateGameDetail', { clear: true }, { root: true }).finally(() => {
            commit('setLoading', false);
          });
          router.replace('/Games');
        } else {
          const { href: url } = router.resolve({
            path: 'Games',
            query: { t: type, c: cat },
          });
          if (url) {
            window.open(url, 'games');
          }
        }
      }
    },
  },
};
