import { GAME_FAVORITE_CAT, GAME_CHAMPION_CAT } from '@/config';
import i18n from '@/i18n';
import store from '@/store';

/**
 * 所有玩法數據的資料
 * WagerPos : 1主 2客 3和 4大 5小 4單 5雙
 *  name: 'HandiCap',
 *?  玩法名
 *  typeIdList: [101, 103],
 *?  有哪些typeIdList 是在此分類下
 *  wagerPos: [1, 2],
 *?  對應API的WagerPos
 *  showMethod: ['topPlayMethod', 'bottomPlayMethod'],
 *?  顯示method的key
 *  showOdd: ['topPlayOdd', 'bottomPlayOdd'],
 *?  顯示Odd的key
 *  betCutLineDealFunc:
 *?  下注送出時組成CutLine所需的資料
 */
export const PlayMethodData = {
  // 讓分: 101 讓分, 103 台盤讓分, 121 局數讓分, 124 盤數讓分
  HandiCap: {
    name: 'HandiCap',
    typeIdList: [101, 103, 106, 121, 124, 129],
    betCutLineDealFunc: function (oddData) {
      return oddData.HomeHdp !== '' ? oddData.HomeHdp : oddData.AwayHdp;
    },
  },
  // 大小: 102 大小, 104 台盤大小, 109 棒球大小, 122 局數大小, 125 總分大小, 131 單隊總分大小
  BigSmall: {
    name: 'BigSmall',
    typeIdList: [102, 104, 109, 122, 125, 131, 134],
    betCutLineDealFunc: function (oddData) {
      return oddData.OULine;
    },
  },
  // 單雙: 105 單雙, 113 足球單雙, 126 局數單雙, 128 總分單雙, 132 單隊總分單雙
  OddEven: {
    name: 'OddEven',
    typeIdList: [105, 113, 126, 128, 132],
    betCutLineDealFunc: function () {
      return 0;
    },
  },
  // 獨贏: 110 足球獨贏, 111 獨贏, 123 局數獨贏, 127 盤數獨贏
  SoloWin: {
    name: 'SoloWin',
    typeIdList: [110, 111, 123, 127],
    betCutLineDealFunc: function () {
      return 0;
    },
  },
  // 首尾分: 117 首分, 118 尾分
  FirstLast: {
    name: 'FirstLast',
    typeIdList: [117, 118],
    betCutLineDealFunc: function () {
      return 0;
    },
  },
  // 112 波膽
  Bold: {
    name: 'Bold',
    typeIdList: [112],
    betCutLineDealFunc: function (oddData) {
      return oddData.OULine;
    },
  },
  // 116 半全場
  HalfAll: {
    name: 'HalfAll',
    typeIdList: [116],
    betCutLineDealFunc: function (oddData) {
      return oddData.OULine;
    },
  },
  // 119 入球數
  Goal: {
    name: 'Goal',
    typeIdList: [119],
    betCutLineDealFunc: function (oddData) {
      return oddData.OULine;
    },
  },
  //  133 冠軍
  Champion: {
    name: 'Champion',
    typeIdList: [133],
    betCutLineDealFunc: function (oddData) {
      return '';
    },
  },
  // 106 一輸二贏
  Other: {
    name: 'Other',
    typeIdList: [106],
    betCutLineDealFunc: function () {
      return 0;
    },
  },
};

/**
 * WagerData 轉成 遊戲注格面板 的顯示格式
 * @param {Number} catID     球種ID
 * @param {Object} wagerData team底下的wager
 * @param {Object} rowIndex  team底下的第幾個row
 * @returns {Object}
 *?  topPlayMethod    {String} 上方玩法
 *?  topPlayOdd       {String} 上方賠率
 *?  bottomPlayMethod {String} 下方玩法
 *?  bottomPlayOdd    {String} 下方賠率
 *?  playMethodData   {Object} 參考 PlayMethodData
 *?  layoutType       {Object} 版型分類
 *     > normal: 預設版型
 *     > single: 只顯示一個Odd
 */
export function WagerDataToShowData(SetFlag, wagerData, rowIndex) {
  if (SetFlag === undefined) {
    console.error('SetFlag is undefined');
  }

  if (!wagerData?.isNoData) {
    if (wagerData.Odds[rowIndex] === undefined || wagerData.Odds[rowIndex].Status !== 1) {
      return oddDataToPlayData(SetFlag, null, null);
    } else {
      return oddDataToPlayData(SetFlag, wagerData.WagerTypeID, wagerData.Odds[rowIndex]);
    }
  } else {
    return oddDataToPlayData(SetFlag, null, null);
  }
}

// 主盤口 顯示的資料
export function oddDataToPlayData(SetFlag, wagerTypeID = null, oddData = null) {
  if (SetFlag === undefined) {
    console.error('SetFlag is undefined');
  }

  const adjustment = store?.getters?.userOddsAdjustment
    ? parseFloat(store?.getters?.userOddsAdjustment)
    : 0;

  let topPlayMethod = '';
  let topPlayOdd = '';
  let topWagerPos = '';
  let bottomPlayMethod = '';
  let bottomPlayOdd = '';
  let bottomWagerPos = '';
  let drewPlayOdd = '';
  let drewWagerPos = '';
  let layoutType = 'normal';
  let playMethodData = null;
  let lotteryContinuousData = {
    bigContinuous:0,
    smallContinuous:0,
    oddContinuous:0,
    evenContinuous:0,
  };

  if (oddData !== null) {
    // 讓分
    if (PlayMethodData.HandiCap.typeIdList.indexOf(wagerTypeID) !== -1) {
      topPlayMethod = oddData.HomeHdp;
      topPlayOdd = oddData.HomeHdpOdds;
      topWagerPos = 1;
      bottomPlayMethod = oddData.AwayHdp;
      bottomPlayOdd = oddData.AwayHdpOdds;
      bottomWagerPos = 2;
      playMethodData = PlayMethodData.HandiCap;
    }
    // 大小
    else if (PlayMethodData.BigSmall.typeIdList.indexOf(wagerTypeID) !== -1) {
      topPlayMethod = oddData.OULine;
      topPlayOdd = oddData.OverOdds;
      topWagerPos = 4;
      bottomPlayMethod = i18n.t('Common.Small');
      bottomPlayOdd = oddData.UnderOdds;
      bottomWagerPos = 5;
      playMethodData = PlayMethodData.BigSmall;
      lotteryContinuousData.bigContinuous = oddData?.BigContinuous ?? 0;
      lotteryContinuousData.smallContinuous = oddData?.SmallContinuous ?? 0;
    }
    // 單雙
    else if (PlayMethodData.OddEven.typeIdList.indexOf(wagerTypeID) !== -1) {
      topPlayMethod = i18n.t('Common.Single');
      topPlayOdd = oddData.OverOdds;
      topWagerPos = 4;
      bottomPlayMethod = i18n.t('Common.Double');
      bottomPlayOdd = oddData.UnderOdds;
      bottomWagerPos = 5;
      playMethodData = PlayMethodData.OddEven;
      lotteryContinuousData.oddContinuous = oddData?.OddContinuous ?? 0;
      lotteryContinuousData.evenContinuous = oddData?.EvenContinuous ?? 0;
    }
    // 獨贏
    else if (PlayMethodData.SoloWin.typeIdList.indexOf(wagerTypeID) !== -1) {
      topPlayOdd = oddData.HomeOdds;
      topWagerPos = 1;
      bottomPlayOdd = oddData.AwayOdds;
      bottomWagerPos = 2;
      drewPlayOdd = oddData.DrewOdds;
      drewWagerPos = 3;
      layoutType = 'single';
      playMethodData = PlayMethodData.SoloWin;
    }
    // 首尾分
    else if (PlayMethodData.FirstLast.typeIdList.indexOf(wagerTypeID) !== -1) {
      topPlayMethod = oddData.HomeHdp;
      topPlayOdd = oddData.HomeHdpOdds;
      topWagerPos = 1;
      bottomPlayMethod = oddData.AwayHdp;
      bottomPlayOdd = oddData.AwayHdpOdds;
      bottomWagerPos = 2;
      playMethodData = PlayMethodData.FirstLast;
    }
    // 波瞻
    else if (PlayMethodData.Bold.typeIdList.indexOf(wagerTypeID) !== -1) {
      drewPlayOdd = oddData.DrewOdds;
      drewWagerPos = 3;
      playMethodData = PlayMethodData.Bold;
    }
    // 半全場
    else if (PlayMethodData.HalfAll.typeIdList.indexOf(wagerTypeID) !== -1) {
      drewPlayOdd = oddData.DrewOdds;
      drewWagerPos = 3;
      playMethodData = PlayMethodData.HalfAll;
    }
    // 入球數
    else if (PlayMethodData.Goal.typeIdList.indexOf(wagerTypeID) !== -1) {
      drewPlayOdd = oddData.DrewOdds;
      drewWagerPos = 3;
      playMethodData = PlayMethodData.Goal;
    }
    // 冠軍
    else if (PlayMethodData.Champion.typeIdList.includes(wagerTypeID)) {
      topPlayOdd = oddData.HomeOdds;
      topWagerPos = 1;
      playMethodData = PlayMethodData.Champion;
    }
    // 其他
    else if (PlayMethodData.Other.typeIdList.indexOf(wagerTypeID) !== -1) {
      topPlayOdd = oddData.HomeHdpOdds;
      topWagerPos = 1;
      bottomPlayOdd = oddData.AwayHdpOdds;
      bottomWagerPos = 2;
      layoutType = 'single';
      playMethodData = PlayMethodData.Other;
    } else {
      console.error('wagerTypeID not found:', wagerTypeID);
    }

    // 主客場對調
    // 大小, 單雙 不能對調
    if (
      !SetFlag &&
      PlayMethodData.BigSmall.typeIdList.indexOf(wagerTypeID) === -1 &&
      PlayMethodData.OddEven.typeIdList.indexOf(wagerTypeID) === -1 &&
      !PlayMethodData.Champion.typeIdList.includes(wagerTypeID)
    ) {
      [topPlayMethod, bottomPlayMethod] = [bottomPlayMethod, topPlayMethod];
      [topPlayOdd, bottomPlayOdd] = [bottomPlayOdd, topPlayOdd];
      [topWagerPos, bottomWagerPos] = [bottomWagerPos, topWagerPos];
    }
  }

  if (topPlayOdd) {
    topPlayOdd = (parseFloat(topPlayOdd) + adjustment).toFixed(3);
  }

  if (bottomPlayOdd) {
    bottomPlayOdd = (parseFloat(bottomPlayOdd) + adjustment).toFixed(3);
  }

  if (drewPlayOdd) {
    drewPlayOdd = (parseFloat(drewPlayOdd) + adjustment).toFixed(3);
  }

  return {
    topPlayMethod,
    topPlayOdd,
    topWagerPos,
    bottomPlayMethod,
    bottomPlayOdd,
    bottomWagerPos,
    drewPlayOdd,
    drewWagerPos,
    layoutType,
    playMethodData,
    lotteryContinuousData,
  };
}

// 更多 顯示的資料
export function oddDataToMorePlayData(SetFlag, wagerTypeID = null, oddData = null, m2 = false) {
  if (SetFlag === undefined) {
    console.error('SetFlag is undefined');
  }

  const adjustment = store?.getters?.userOddsAdjustment
    ? parseFloat(store?.getters?.userOddsAdjustment)
    : 0;

  // 讓分
  if (PlayMethodData.HandiCap.typeIdList.indexOf(wagerTypeID) !== -1) {
    const showHdp = oddData.HomeHdp === '' ? oddData.AwayHdp : oddData.HomeHdp;
    let homeShow = '';
    let awayShow = '';
    if (oddData.HomeHdp === '') {
      if (!SetFlag) {
        homeShow = '-' + showHdp;
        awayShow = '+' + showHdp;
      } else {
        homeShow = '+' + showHdp;
        awayShow = '-' + showHdp;
      }
    } else {
      if (!SetFlag) {
        homeShow = '+' + showHdp;
        awayShow = '-' + showHdp;
      } else {
        homeShow = '-' + showHdp;
        awayShow = '+' + showHdp;
      }
    }
    if (Number(showHdp) === 0 || showHdp === 'PK') {
      homeShow = showHdp;
      awayShow = showHdp;
    }

    let homeOdds = oddData.HomeHdpOdds;
    if (homeOdds) {
      homeOdds = (parseFloat(homeOdds) + adjustment).toFixed(3);
    }
    let awayOdds = oddData.AwayHdpOdds;
    if (awayOdds) {
      awayOdds = (parseFloat(awayOdds) + adjustment).toFixed(3);
    }
    if (!SetFlag) {
      return [
        {
          showMethod: `${i18n.t('Common.Away')} ${homeShow}`,
          showOdd: awayOdds,
          wagerPos: 2,
        },
        {
          showMethod: `${i18n.t('Common.Home')} ${awayShow}`,
          showOdd: homeOdds,
          wagerPos: 1,
        },
      ];
    } else {
      return [
        {
          showMethod: `${i18n.t('Common.Home')} ${homeShow}`,
          showOdd: homeOdds,
          wagerPos: 1,
        },
        {
          showMethod: `${i18n.t('Common.Away')} ${awayShow}`,
          showOdd: awayOdds,
          wagerPos: 2,
        },
      ];
    }
  }
  // 大小
  else if (PlayMethodData.BigSmall.typeIdList.indexOf(wagerTypeID) !== -1) {
    let overOdds = oddData.OverOdds;
    if (overOdds) {
      overOdds = (parseFloat(overOdds) + adjustment).toFixed(3);
    }
    let underOdds = oddData.UnderOdds;
    if (underOdds) {
      underOdds = (parseFloat(underOdds) + adjustment).toFixed(3);
    }
    return [
      {
        showMethod: `${i18n.t('Common.Big')} ${oddData.OULine}`,
        showOdd: overOdds,
        wagerPos: 4,
      },
      {
        showMethod: `${i18n.t('Common.Small')} ${oddData.OULine}`,
        showOdd: underOdds,
        wagerPos: 5,
      },
    ];
  }
  // 單雙
  else if (PlayMethodData.OddEven.typeIdList.indexOf(wagerTypeID) !== -1) {
    let overOdds = oddData.OverOdds;
    if (overOdds) {
      overOdds = (parseFloat(overOdds) + adjustment).toFixed(3);
    }
    let underOdds = oddData.UnderOdds;
    if (underOdds) {
      underOdds = (parseFloat(underOdds) + adjustment).toFixed(3);
    }
    return [
      {
        showMethod: i18n.t('Common.Single'),
        showOdd: overOdds,
        wagerPos: 4,
      },
      {
        showMethod: i18n.t('Common.Double'),
        showOdd: underOdds,
        wagerPos: 5,
      },
    ];
  }
  // 獨贏
  else if (PlayMethodData.SoloWin.typeIdList.indexOf(wagerTypeID) !== -1) {
    let homeOdds = oddData.HomeOdds;
    if (homeOdds) {
      homeOdds = (parseFloat(homeOdds) + adjustment).toFixed(3);
    }
    let awayOdds = oddData.AwayOdds;
    if (awayOdds) {
      awayOdds = (parseFloat(awayOdds) + adjustment).toFixed(3);
    }
    let drewOdds = oddData.DrewOdds;
    if (drewOdds) {
      drewOdds = (parseFloat(drewOdds) + adjustment).toFixed(3);
    }
    if (!SetFlag) {
      const resArr = [
        {
          showMethod: i18n.t('Common.Away'),
          showOdd: awayOdds,
          wagerPos: 2,
        },
        {
          showMethod: i18n.t('Common.Home'),
          showOdd: homeOdds,
          wagerPos: 1,
        },
      ];
      if (drewOdds) {
        if (m2) {
          resArr.push({
            showMethod: i18n.t('Common.Drew'),
            showOdd: drewOdds,
            wagerPos: 3,
          });
        } else {
          resArr.splice(1, 0, {
            showMethod: i18n.t('Common.Drew'),
            showOdd: drewOdds,
            wagerPos: 3,
          });
        }
      }
      return resArr;
    } else {
      const resArr = [
        {
          showMethod: i18n.t('Common.Home'),
          showOdd: homeOdds,
          wagerPos: 1,
        },
        {
          showMethod: i18n.t('Common.Away'),
          showOdd: awayOdds,
          wagerPos: 2,
        },
      ];
      if (drewOdds) {
        if (m2) {
          resArr.push({
            showMethod: i18n.t('Common.Drew'),
            showOdd: drewOdds,
            wagerPos: 3,
          });
        } else {
          resArr.splice(1, 0, {
            showMethod: i18n.t('Common.Drew'),
            showOdd: drewOdds,
            wagerPos: 3,
          });
        }
      }
      return resArr;
    }
  }
  // 首尾分
  else if (PlayMethodData.FirstLast.typeIdList.indexOf(wagerTypeID) !== -1) {
    const showHdp = oddData.HomeHdp === '' ? oddData.AwayHdp : oddData.HomeHdp;
    let homeShow = '';
    let awayShow = '';
    if (oddData.HomeHdp === '') {
      if (!SetFlag) {
        homeShow = '-' + showHdp;
        awayShow = '+' + showHdp;
      } else {
        homeShow = '+' + showHdp;
        awayShow = '-' + showHdp;
      }
    } else {
      if (!SetFlag) {
        homeShow = '+' + showHdp;
        awayShow = '-' + showHdp;
      } else {
        homeShow = '-' + showHdp;
        awayShow = '+' + showHdp;
      }
    }
    if (Number(showHdp) === 0) {
      homeShow = showHdp;
      awayShow = showHdp;
    }
    let homeOdds = oddData.HomeHdpOdds;
    if (homeOdds) {
      homeOdds = (parseFloat(homeOdds) + adjustment).toFixed(3);
    }
    let awayOdds = oddData.AwayHdpOdds;
    if (awayOdds) {
      awayOdds = (parseFloat(awayOdds) + adjustment).toFixed(3);
    }
    if (!SetFlag) {
      return [
        {
          showMethod: `${i18n.t('Common.Away')} ${homeShow}`,
          showOdd: awayOdds,
          wagerPos: 2,
        },
        {
          showMethod: `${i18n.t('Common.Home')} ${awayShow}`,
          showOdd: homeOdds,
          wagerPos: 1,
        },
      ];
    } else {
      return [
        {
          showMethod: `${i18n.t('Common.Home')} ${homeShow}`,
          showOdd: homeOdds,
          wagerPos: 1,
        },
        {
          showMethod: `${i18n.t('Common.Away')} ${awayShow}`,
          showOdd: awayOdds,
          wagerPos: 2,
        },
      ];
    }
  }
  // 冠軍
  else if (PlayMethodData.Champion.typeIdList.includes(wagerTypeID)) {
    let homeOdds = oddData.HomeOdds;
    if (homeOdds) {
      homeOdds = (parseFloat(homeOdds) + adjustment).toFixed(3);
    }
    return [
      {
        showMethod: oddData.HomeTeamStr,
        showOdd: homeOdds,
        wagerPos: 1,
      },
    ];
  }
  // 波膽
  else if (PlayMethodData.Bold.typeIdList.includes(wagerTypeID)) {
    let drewOdds = oddData.DrewOdds;
    if (drewOdds) {
      drewOdds = (parseFloat(drewOdds) + adjustment).toFixed(3);
    }
    return {
      showMethod: oddData.OULine,
      showOdd: drewOdds,
      wagerPos: 3,
    };
  }
  // 半全場
  else if (PlayMethodData.HalfAll.typeIdList.includes(wagerTypeID)) {
    let drewOdds = oddData.DrewOdds;
    if (drewOdds) {
      drewOdds = (parseFloat(drewOdds) + adjustment).toFixed(3);
    }
    return {
      showMethod: oddData.OULine,
      showOdd: drewOdds,
      wagerPos: 3,
    };
  }
  // 入球數
  else if (PlayMethodData.Goal.typeIdList.includes(wagerTypeID)) {
    let drewOdds = oddData.DrewOdds;
    if (drewOdds) {
      drewOdds = (parseFloat(drewOdds) + adjustment).toFixed(3);
    }
    return {
      showMethod: oddData.OULine,
      showOdd: drewOdds,
      wagerPos: 3,
    };
  }
  // 其他
  else if (PlayMethodData.Other.typeIdList.indexOf(wagerTypeID) !== -1) {
    let homeOdds = oddData.HomeHdpOdds;
    if (homeOdds) {
      homeOdds = (parseFloat(homeOdds) + adjustment).toFixed(3);
    }
    let awayOdds = oddData.AwayHdpOdds;
    if (awayOdds) {
      awayOdds = (parseFloat(awayOdds) + adjustment).toFixed(3);
    }
    if (!SetFlag) {
      return [
        {
          showMethod: i18n.t('Common.Away'),
          showOdd: awayOdds,
          wagerPos: 2,
        },
        {
          showMethod: i18n.t('Common.Home'),
          showOdd: homeOdds,
          wagerPos: 1,
        },
      ];
    } else {
      return [
        {
          showMethod: i18n.t('Common.Home'),
          showOdd: homeOdds,
          wagerPos: 1,
        },
        {
          showMethod: i18n.t('Common.Away'),
          showOdd: awayOdds,
          wagerPos: 2,
        },
      ];
    }
  }
  //
  else {
    return [];
  }
}

// 購物車 顯示的資料
export function cartDataToDisplayData(cartData) {
  const playData = cartData.playData;
  const SetFlag = cartData.SetFlag;
  let judgeWagerPos = cartData.wagerPos;
  let showBetTitle = '';
  let showCutLine = '';
  let showOdd = '';
  let showGameTypeLabel = '';
  let wagerGrpLabel = '';

  // 讓分
  if (playData.playMethodData.name === 'HandiCap') {
    if (!SetFlag) {
      if (judgeWagerPos === 1) {
        judgeWagerPos = 2;
      } else if (judgeWagerPos === 2) {
        judgeWagerPos = 1;
      }
    }
    if (judgeWagerPos === 1) {
      showBetTitle = cartData?.HomeTeamStr;
      if (playData.topPlayMethod === '') {
        if (playData.bottomPlayMethod === 'PK') showCutLine = playData.bottomPlayMethod;
        else showCutLine = '+' + playData.bottomPlayMethod;
      } else {
        if (playData.topPlayMethod === 'PK') showCutLine = playData.topPlayMethod;
        else showCutLine = '-' + playData.topPlayMethod;
      }
      showOdd = playData.topPlayOdd;
    } else if (judgeWagerPos === 2) {
      showBetTitle = cartData?.AwayTeamStr;
      if (playData.topPlayMethod === '') {
        if (playData.bottomPlayMethod === 'PK') showCutLine = playData.bottomPlayMethod;
        else showCutLine = '-' + playData.bottomPlayMethod;
      } else {
        if (playData.topPlayMethod === 'PK') showCutLine = playData.topPlayMethod;
        else showCutLine = '+' + playData.topPlayMethod;
      }
      showOdd = playData.bottomPlayOdd;
    } else {
      console.error(`cartDataToDisplayData no this wagerPos ${cartData.wagerPos}`);
    }
    if (playData.topPlayMethod === '0' || playData.bottomPlayMethod === '0') {
      showCutLine = '0';
    }
  }
  // 大小
  else if (playData.playMethodData.name === 'BigSmall') {
    if (judgeWagerPos === 4) {
      showBetTitle = i18n.t('Common.Big');
      showCutLine = playData.topPlayMethod;
      showOdd = playData.topPlayOdd;
    } else if (judgeWagerPos === 5) {
      showBetTitle = i18n.t('Common.Small');
      showCutLine = playData.topPlayMethod;
      showOdd = playData.bottomPlayOdd;
    } else {
      console.error(`cartDataToDisplayData no this wagerPos ${cartData.wagerPos}`);
    }
  }
  // 單雙
  else if (playData.playMethodData.name === 'OddEven') {
    if (judgeWagerPos === 4) {
      showBetTitle = i18n.t('Common.Single');
      showOdd = playData.topPlayOdd;
    } else if (judgeWagerPos === 5) {
      showBetTitle = i18n.t('Common.Double');
      showOdd = playData.bottomPlayOdd;
    } else {
      console.error(`cartDataToDisplayData no this wagerPos ${cartData.wagerPos}`);
    }
  }
  // 獨贏
  else if (playData.playMethodData.name === 'SoloWin') {
    if (!SetFlag) {
      if (judgeWagerPos === 1) {
        judgeWagerPos = 2;
      } else if (judgeWagerPos === 2) {
        judgeWagerPos = 1;
      }
    }
    if (judgeWagerPos === 1) {
      showBetTitle = cartData?.HomeTeamStr;
      showOdd = playData.topPlayOdd;
      showCutLine = 'PK';
    } else if (judgeWagerPos === 2) {
      showBetTitle = cartData?.AwayTeamStr;
      showOdd = playData.bottomPlayOdd;
      showCutLine = 'PK';
    } else if (judgeWagerPos === 3) {
      showBetTitle = i18n.t('Common.DrewOdd');
      showOdd = playData.drewPlayOdd;
      showCutLine = '';
    } else {
      console.error(`cartDataToDisplayData no this wagerPos ${cartData.wagerPos}`);
    }
  }
  // 首尾分
  else if (playData.playMethodData.name === 'FirstLast') {
    if (!SetFlag) {
      if (judgeWagerPos === 1) {
        judgeWagerPos = 2;
      } else if (judgeWagerPos === 2) {
        judgeWagerPos = 1;
      }
    }
    if (judgeWagerPos === 1) {
      showBetTitle = cartData?.HomeTeamStr;
      if (playData.topPlayMethod === '') {
        showCutLine = playData.bottomPlayMethod;
      } else {
        showCutLine = playData.topPlayMethod;
      }
      showOdd = playData.topPlayOdd;
    } else if (judgeWagerPos === 2) {
      showBetTitle = cartData?.AwayTeamStr;
      if (playData.topPlayMethod === '') {
        showCutLine = playData.bottomPlayMethod;
      } else {
        showCutLine = playData.topPlayMethod;
      }
      showOdd = playData.bottomPlayOdd;
    } else {
      console.error(`cartDataToDisplayData no this wagerPos ${cartData.wagerPos}`);
    }
    if (playData.topPlayMethod === '' || playData.bottomPlayMethod === '') {
      showCutLine = '';
    }
  }
  // 波膽
  else if (playData.playMethodData.name === 'Bold') {
    showBetTitle = cartData.OULine;
    showOdd = playData.drewPlayOdd;
  }
  // 半全場
  else if (playData.playMethodData.name === 'HalfAll') {
    showBetTitle = cartData.OULine;
    showOdd = playData.drewPlayOdd;
  }
  // 總得分
  else if (playData.playMethodData.name === 'Goal') {
    showBetTitle = cartData.OULine;
    showOdd = playData.drewPlayOdd;
  }
  // 冠軍
  else if (playData.playMethodData.name === 'Champion') {
    showBetTitle = cartData.HomeTeamStr;
    showOdd = playData.topPlayOdd;
  }
  // 其他
  else if (playData.playMethodData.name === 'Other') {
    if (!SetFlag) {
      if (judgeWagerPos === 1) {
        judgeWagerPos = 2;
      } else if (judgeWagerPos === 2) {
        judgeWagerPos = 1;
      }
    }
    if (judgeWagerPos === 1) {
      showBetTitle = cartData?.HomeTeamStr;
      showOdd = playData.topPlayOdd;
      if (cartData.HomeHdp === '') {
        showCutLine = '-' + cartData.AwayHdp;
      } else {
        showCutLine = '+' + cartData.HomeHdp;
      }
    } else if (judgeWagerPos === 2) {
      showBetTitle = cartData?.AwayTeamStr;
      showOdd = playData.bottomPlayOdd;
      if (cartData.AwayHdp === '') {
        showCutLine = '-' + cartData.HomeHdp;
      } else {
        showCutLine = '+' + cartData.AwayHdp;
      }
    } else {
      console.error(`cartDataToDisplayData no this wagerPos ${cartData.wagerPos}`);
    }
  } else {
    console.error('playData.playMethodData.name error:', playData.playMethodData.name);
  }

  if (cartData?.ItemName) {
    wagerGrpLabel = `- [${cartData.ItemName}]`;
  } else {
    // 波膽處理
    if (cartData.WagerTypeID === 112) {
      wagerGrpLabel = `- [${i18n.t('Common.Bold')}]`;
    }
  }

  if (cartData.GameTypeID === 2) {
    if ([35].includes(cartData.WagerGrpID)) {
      showGameTypeLabel = `${cartData.CatNameStr} ${wagerGrpLabel}`;
    } else {
      showGameTypeLabel = `${cartData.CatNameStr}${cartData.GameTypeLabel} ${wagerGrpLabel}`;
    }
  } else {
    showGameTypeLabel = `${cartData.CatNameStr} ${wagerGrpLabel}`;
  }

  const adjustment = store?.getters?.userOddsAdjustment
    ? parseFloat(store?.getters?.userOddsAdjustment)
    : 0;

  let showOdd2 = showOdd;
  if (cartData.CatID in store.state.Game.OddsAdj) {
    const ParlayAdj = store.state.Game.OddsAdj[cartData.CatID];
    showOdd2 = (Number(showOdd) + Number(ParlayAdj) - adjustment).toFixed(3);
    if (showOdd2 < 0.01) {
      showOdd2 = 0.01;
    }
  }

  return {
    showBetTitle,
    showCutLine,
    showOdd,
    showOdd2,
    showGameTypeLabel,
    wagerGrpLabel,
  };
}

// 取得彩種 menu icon
export function getMenuIconByCatID(catID) {
  const map = Object.freeze({
    1: 'icon_sportMenu_soccer.svg', // 足球
    5: 'icon_sportMenu_football.svg', // 美足
    21: 'icon_sportMenu_pingpong.svg', // 乒乓
    22: 'icon_sportMenu_badminton.svg', // 羽球
    23: 'icon_sportMenu_volleyball.svg', // 排球
    24: 'icon_sportMenu_billiard.svg', // 撞球
    31: 'icon_sportMenu_worldCup.svg', // 世界盃
    32: 'icon_sportMenu_UEFA.svg', // 歐洲杯
    55: 'icon_sportMenu_tennis.svg', // 網球
    72: 'icon_horse.svg', // 賽馬賽狗
    82: 'icon_sportMenu_hockey.svg', // 冰球
    83: 'icon_lottery.svg', // 彩球
    84: 'icon_invest.svg', // 指數
    85: 'icon_sportMenu_pcgame.svg', // 電競
    101: 'icon_sportMenu_baseball.svg', // 棒球
    102: 'icon_sportMenu_basketball.svg', // 籃球
    888888: 'fire.gif',
    [GAME_FAVORITE_CAT]: 'icon_sportMenu_star.svg', // 收藏
    [GAME_CHAMPION_CAT]: 'icon_sportMenu_baseball.svg',
    default: 'icon_sportMenu_soccer.svg',
  });

  return map[catID] || map.default;
}

// 取得彩種 背景圖
export function getBoardImageByCatId(catID) {
  const map = Object.freeze({
    1: 'soccer.jpg',
    5: 'Rugby.jpg',
    21: 'pingpong.jpg',
    22: 'badminton.jpg',
    23: 'volleyball.jpg',
    24: 'billiard.jpg',
    31: 'worldCup.jpg',
    32: 'champion.jpg',
    55: 'tennis.jpg',
    72: null,
    82: 'hockey.jpg',
    83: null,
    84: null,
    85: 'pcgame.jpg',
    101: 'baseball.jpg',
    102: 'basketball.jpg',
    888888: null,
    [GAME_FAVORITE_CAT]: null,
    [GAME_CHAMPION_CAT]: 'baseball.jpg',
    default: 'soccer.jpg',
  });

  return map[catID] || map.default;
}

// 取得彩種 顏色
export function getColorByCatId(catID) {
  const map = Object.freeze({
    1: '#61b880',
    5: '#b25332',
    21: '#c6914b',
    22: '#d56f84',
    23: '#e0bf36',
    24: '#A470AC',
    31: '#65cc56',
    32: '#FFFC4C',
    55: '#90b270',
    72: '#14be9e',
    82: '#14be9e',
    83: '#f07f3f',
    84: '#fe5186',
    85: '#7894dc',
    101: '#caba62',
    102: '#ae6a3d',
    888888: '#ffffff',
    [GAME_FAVORITE_CAT]: '#ffffff',
    [GAME_CHAMPION_CAT]: '#caba62',
    default: '#ffffff',
  });

  return map[catID] || map.default;
}

// 下注籌碼資料
export const chipsData = [
  {
    value: 100,
    img: 'icon_chip_01.png',
  },
  {
    value: 500,
    img: 'icon_chip_02.png',
  },
  {
    value: 1000,
    img: 'icon_chip_03.png',
  },
  {
    value: 2000,
    img: 'icon_chip_04.png',
  },
  {
    value: 5000,
    img: 'icon_chip_05.png',
  },
  {
    value: 10000,
    img: 'icon_chip_06.png',
  },
  {
    value: 20000,
    img: 'icon_chip_07.png',
  },
  {
    value: 50000,
    img: 'icon_chip_08.png',
  },
  {
    value: 100000,
    img: 'icon_chip_09.png',
  },
];
